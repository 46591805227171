<template>
  <div v-if="priceDetail" class="container page-bg">
    <div v-html="alipayForm"></div>
    <Mpopup v-model="showPay" title="请选择支付方式" :show-close="true" height="30vh">
      <div style="padding-left: 1rem;">
        <div class="radio-wrap" @click="pay('WEIXIN')">
          <div v-if="$store.state.globalData.coname !== 'alipay'" class="radio">
            <!-- <input v-model="payway" type="radio" name="payway" value="WEIXIN"> -->
            <span class="wechatpay"></span>微信支付<label></label>
          </div>
        </div>
        <div v-if="!$store.state.globalData.isWechat" class="radio" @click="pay('ZHIFUBAO')">
          <!-- <input v-model="payway" type="radio" name="payway" value="ZHIFUBAO"> -->
          <span class="alipay"></span>支付宝支付<label></label>
        </div>
      </div>
    </Mpopup>
    <div class="p-title">
      费用明细
    </div>
    <div v-if="paywayen == '' && priceDetail.pprice != '0.0'" class="p-prepay">
      <div>已预付费用</div>
      <div class="count">
        {{ priceDetail.pprice }}元
      </div>
    </div>
    <div v-if="paywayen != ''" class="p-prepay">
    </div>
    <div class="p-detail">
      <div v-if="priceDetail.firstprice!=0" class="d-item">
        <div>首重</div>
        <div class="count">
          {{ priceDetail.firstprice }}元
        </div>
      </div>
      <div v-if="priceDetail.nightFee && priceDetail.nightFee != 0" class="d-item">
        <div>夜间取件费</div>
        <div class="count">
          {{ priceDetail.nightFee }}元
        </div>
      </div>
      <div v-if="priceDetail.costprice!=0" class="d-item">
        <div>优惠券抵扣</div>
        <div class="count">
          - {{ priceDetail.costprice }}元
        </div>
      </div>
      <div v-if="priceDetail.kd100Free && priceDetail.kd100Free != 0" class="d-item">
        <div>平台优惠</div>
        <div class="count">
          - {{ priceDetail.kd100Free }}元
        </div>
      </div>
    </div>
    <div class="p-detail">
      <div v-if="priceDetail.overprice!=0" class="d-item">
        <div>续重</div>
        <div class="count">
          {{ priceDetail.overprice }}元
        </div>
      </div>
      <div v-if="priceDetail.valinspay!=0" class="d-item">
        <div>保价费</div>
        <div class="count">
          {{ priceDetail.valinspay+'元' }}
        </div>
      </div>
      <div v-if="priceDetail.pickprice!=0" class="d-item">
        <div>包装费</div>
        <div class="count">
          {{ priceDetail.pickprice }}元
        </div>
      </div>
      <div v-if="priceDetail.visitfee" class="d-item">
        <div>上门费</div>
        <div class="count">
          {{ priceDetail.visitfee }}元
        </div>
      </div>
      <div v-if="priceDetail.transfee" class="d-item">
        <div>送货费</div>
        <div class="count">
          {{ priceDetail.transfee }}元
        </div>
      </div>
      <div v-if="priceDetail.otherprice!=0" class="d-item">
        <div>其他费用</div>
        <div class="count">
          {{ priceDetail.otherprice }}元
        </div>
      </div>
    </div>
    <div v-if="priceDetail.nightFeeStatus && priceDetail.nightFeeStatus == 'REFUND'" class="p-prepay">
      <div>已退费用</div>
    </div>
    <div v-if="priceDetail.nightFeeStatus && priceDetail.nightFeeStatus == 'REFUND'" class="p-detail">
      <div class="d-item">
        <div>夜间取件费</div>
        <div class="count">
          -{{ priceDetail.nightFee }}元
        </div>
      </div>
    </div>
    <div class="p-allfee">
      <div>总费用</div>
      <div>{{ priceDetail.totalprice }}元</div>
    </div>
    <div v-if="paystatus != 'PAYED' && optBtn == 1">
      <div class="bottom-box">
        <div class="btn-tips">
          <div>若对价格有疑问，请先联系快递员再支付</div>
          <div v-if="priceDetail.price > 150 && paywayen == 'WXAPP_PAYAFTER'">
            费用超过微信支付分免密支付最大限额,需手动支付
          </div>
        </div>
        <button :class="[paywayen != '' && 150 >= priceDetail.price ? 'tl-btn' : '']" class="footer-btn-fixed" @click="toPay">
          <span class="btn-fs">确认并支付</span>
          <span v-if="paywayen == 'WXAPP_PAYAFTER' && 150 >= priceDetail.price" class="btn-fg">(微信支付分免密支付)</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
import util from "lib/util"
import { mapActions } from 'vuex'
export default {
  components: {
    Mpopup
  },
  data() {
    return {
      alipayForm: "",
      expid: '',
      dispatchid: '',
      paystatus: '',
      paywayen: '',
      optBtn: 1,
      priceDetail: null,
      onlyView: false,
      showPay: false,
      aaa: ''
    }
  },
  created () {
    this.expid = this.$route.query.expid
    this.dispatchid = this.$route.query.dispatchid
    this.paystatus = this.$route.query.paystatus
    this.paywayen = this.$route.query.paywayen
    this.optBtn = this.$route.query.optBtn
    this.login(()=> {
      this.querypriceinfo()
    })
    if(this.$store.state.globalData.isWechat) {
      util.loadScript("//res.wx.qq.com/open/js/jweixin-1.0.0.js", function() {})
    }
  },
  beforeDestroy() {
    clearInterval(this.tCheckPay)
  },
  methods: {
    callpay: function () {
      var f = this.wxPay
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', f, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', f)
          document.attachEvent('onWeixinJSBridgeReady', f)
        }
      } else {
        f()
      }
    },
    wxPay: function () {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        this.jsApiParameters,
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.$$router.back(-1)
          } else if (res.err_msg == "get_brand_wcpay_request:fail") {
            this.$toast("支付失败，请稍后再试")
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            this.$toast("支付失败，请稍后再试")
          }
        }
      );
    },
    checkPay: function() {
      var count = 0
      this.tCheckPay = setInterval(() => {
        count++
        if(count > 60) {
          clearInterval(this.tCheckPay)
        }
        this.getOrderInfo()
      }, 2000)
    },
    getOrderInfo(tag) { // 订单详情
      const url = '/apicenter/order.do?method=getOrderInfo'
      const params = {
        data: {
          dispatchid: this.dispatchid,
          expid: this.expid
        }
      }
      this.$http.post(url, params).then((res) => {
        if (res.detail.paystatus !== 'WAITPAY') {
          this.$router.back(-1)
        }
      })
    },
    pay (type) {
      const loading = this.$loading('加载中')
      var backPath = process.env.NODE_ENV != 'development' ? "/mexpress/" : "/"
      var backUrl = `${backPath}dispatch/detail?expid=${this.expid}&dispatchid=${this.dispatchid}`
      this.$http.post("/apicenter/order.do?method=disparityPay", {
        data: {
          expid: this.expid,
          tradetype: type === 'WEIXIN' && typeof (wx) != "undefined" ? "JSAPI" : "MWEB",
          payway: type,
          quitUrl: encodeURIComponent(backUrl)
        }
      }).then(result => {
        if (type === 'WEIXIN') {
          if (typeof (wx) != "undefined") { //微信公众号支付
            this.jsApiParameters = JSON.parse(result.weixinpayReqStr)
            this.callpay()
          } else { // 微信支付
            if (result.mweb_url) {
              this.checkPay()
              location.href = result.mweb_url
            } else {
              this.$toast('订单错误，请刷新重试')
            }
          }
        } else {
          this.alipayForm = result.alipayReqStr
          this.$nextTick(function() {
            document.forms.punchout_form.submit()
          })
        }
      }).finally(()=> {
        loading.hide()
      })
    },
    querypriceinfo () {
      const loading = this.$loading('加载中')
      let url = '/apicenter/order.do?method=querypriceinfo'
      const params = {
        data: {
          expid: this.expid,
          dispatchid: this.dispatchid
        }
      }
      this.$http.post(url, params).then((res)=> {
        this.priceDetail = res.data
        clearInterval(this.tCheckPay)
      }).finally(()=> {
        loading.hide()
      })
    },
    toPay () {
      this.showPay = true
      /* const params = {
          payway: this.paywayen === 'WXAPP_PAYAFTER' ? 'WXAPP_PAYAFTER' : 'WEIXINAPP',
          expid: this.expid,
          dispatchid: this.dispatchid
        }
        this.payBest(params).then((res)=> {
          setTimeout(()=> {
            uni.navigateBack()
          }, 1000)
        }) */
    },
  }
}
</script>

<style lang="scss" scoped>
.radio {
  position: relative;
  padding: 1em 0;
  line-height: 1rem;
  color: #878787;
}
.radio label {
  float: right;
  vertical-align: -3px;
  width: .5rem;
  height: .5rem;
  padding: .25rem;
  border-radius: 50%;
  margin-right: 1em;
  border: 1px solid;
  background-clip: content-box;
  color: #bebebe;
}
.radio input {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0;
}
.radio input:checked ~ label {
  color: #317ee7;
  background-color: #317ee7;
}
.wechatpay,.alipay {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-size: 22px auto;
  background-repeat: no-repeat;
  vertical-align: -6px;
  margin-right: .625rem;
}
.wechatpay {
  background-image: url(https://cdn.kuaidi100.com/images/m/dispatch/wechatpay.png);
}
.alipay {
  background-image: url(https://cdn.kuaidi100.com/images/m/dispatch/alipay.png)
}
.container {
  height: 100vh;
  background: #fff;
  overflow: auto;
}
.page-bg {
  background: #fff;
}

.p-title {
  font-size: 0.875rem;
  color: #acacac;
  text-align: center;
  padding: 0 0.3125rem 0;
  margin: 1.5625rem 0.625rem 0 0.625rem;
  line-height: 1px;
  border-left: 8.4375rem solid #efefef;
  border-right: 8.4375rem solid #efefef;
  background: #fff;
}
.d-item {
  position: relative;
  font-size: 0.875rem;
  color: #888;
  padding: 0 2.1875rem;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.d-item view:first-child {
  display: flex;
  align-items: center;
}

.d-item view.count {
  color: #888;
  float: right;
  margin-right: 2.1875rem;
}

.d-item img {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-left: 0.3125rem;
}

.footer-bottom {
  width: 100%;
  background: #fff;
  z-index: 9;
  height: 5.625rem;
  position: fixed;
  bottom: 0;
}

/*预付费用*/

.p-prepay {
  margin: 2.1875rem 1.5625rem 0;
  color: #333;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.625rem;
}

.p-otherpay {
  margin: 2.1875rem 1.5625rem 0;
  color: #ff7f02;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.625rem;
}

.p-otherpay view:first-child, .p-prepay view:first-child {
  margin-left: 0.625rem;
}

.p-otherpay view:last-child, .p-prepay view:last-child {
  margin-right: 0.625rem;
}

.p-allfee {
  display: flex;
  font-size: 1rem;
  color: #ff7f02;
  margin-top: 1.875rem;
  justify-content: space-between;
  margin-bottom: 4.5625rem;
  padding: 0 2.1875rem 0.625rem;
}

.p-allfee view:first-child {
  margin-left: 2.1875rem;
}

.p-allfee view:last-child {
  margin-right: 2.1875rem;
}

/*底部btn*/

.bottom-box {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 0.625rem 0 0.9375rem 0;
  bottom: 0;
}

.footer-btn-fixed {
  height: 3rem;
  border: none;
  background-color: #1aad19;
  color: #fff;
  border-radius: 0.25rem;
  line-height: 3rem;
  text-align: center;
  width: 94%;
  margin-left: 3%;
}

.btn-tips {
  font-size: 0.875rem;
  color: #878787;
  text-align: center;
  margin-bottom: 0.9375rem;
}

.tl-btn {
  display: flex;
  flex-direction: column;
  line-height: 1.0625rem;
  justify-content: center;
}
.btn-fs{
  font-size: 1.0625rem;
}
.btn-fg{
  font-size: 0.8125rem;
  color: #fff;
  opacity: 0.7;
}
</style>
