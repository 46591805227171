<template>
  <div class="kd-popup-wrap" :class="{show: showOuter}" :style="{zIndex: zIndex}" @click.stop="maskClick">
    <div class="kd-popup" :class="{show: showInner}" @click.stop @transitionend="handleTranstion">
      <div v-if="showClose" class="kd-popup__close" @click="close" />
      <div class="kd-popup__body" :style="{height: height}">
        <div v-if="title && !hideTitle" class="kd-popup__title">{{ title }}</div>
        <div class="kd-popup__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
  <!-- </uni-popup> -->
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: ''
    },
    hideTitle: { // 不加这个判断 头条小程序会有问题
      type: Boolean,
      default: false
    },
    maskClickable: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: [Number, String],
      default: 99
    },
    noHide: {
      type: Boolean,
      value: false
    },
    height: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      showOuter: false,
      showInner: false
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.$nextTick(() => {
          if (val && !this.showOuter) {
            this.showOuter = true
            // this.$emit('show')
            this.$emit('input', true)
          } else if (this.showInner) {
            this.showInner = false
            // this.$emit('hide')
          }
        })
      },
      immediate: true
    },
    showOuter(val) {
      if (val && !this.showInner) {
        this.$nextTick(() => {
          this.showInner = true
        })
      }
    },
    showInner(val) {
      if (!val && this.showOuter) {
        setTimeout(() => {
          this.showOuter = false
          // this.$emit('hide')
          this.$emit('input', false)
        }, 200)
      }
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.show)
    },
    close(e) {
      this.$emit('close')
      if (!this.noHide) {
        this.showInner = false
      }
    },
    maskClick() {
      if (this.maskClickable) this.close()
    },
    handleTranstion(type) {
      setTimeout(() => {
        this.showInner ? this.$emit('show') : this.$emit('hide')
      }, 0)
    }
  }
}
</script>

<style lang="scss">
.kd-popup-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.6);
  height: 100vh;
  display: none;
  opacity: 0;
  transition: opacity .2s;
  &.show {
    opacity: 1;
    display: block;
  }
}
.kd-popup {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0,100%,0);
  transition: transform .3s;
  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  &.show {
    transition: transform .2s linear;
    transform: translate3d(0,0,0);
  }
	&__close {
		width: 2.1875rem;
		height: 2.1875rem;
		background: url('https://cdn.kuaidi100.com/images/wxapp/icons/ico_popup_close.png') center / 1.3125rem no-repeat;
	}
	&__title {
		padding: 0.9375rem 1.3125rem;
		font-size: 1.0625rem;
		font-weight: bold;
		color: #333;
	}
	&__body {
		display: flex;
		flex-direction: column;
		background-color: #FFF;
    border-radius: 0.625rem 0.625rem 0 0;
    overflow: auto;
	}
}
</style>
